import isUndefined from 'lodash/isUndefined';
import React, { useEffect, useState } from 'react';
import { PrimaryButton, SecondaryButton } from 'shared/components/Button';
import Label from 'shared/components/Label';
import Loader from 'shared/components/Loader';
import Select from 'shared/components/Select';
import { useTheme } from 'shared/hooks/useTheme';
import {
  makeOptions,
  makeModelOptions,
  makeTrims,
  makeRegistrationYears,
} from 'features/detailed-comparator/utils/makeFormOptions';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { useBrand } from 'shared/hooks/useBrand';
import { Product } from 'shared/constants';
import { useTranslation } from 'next-i18next';
import { useComparison } from 'features/vehicle-comparisons';
import FormError from 'shared/components/FormError';
import { ComparisonResponse, FuelCategory } from 'shared/types';
import { FormValues } from 'features/detailed-comparator/types';
import getSubBrandMapping from 'shared/utils/getSubBrandMapping';
import { usePlausible } from 'next-plausible';
import { useManufacturers } from 'features/detailed-comparator/hooks/useManufacturers';
import { useManufacturer } from 'features/detailed-comparator/hooks/useManufacturer';
import { useModels } from 'features/detailed-comparator/hooks/useModels';
import { useDerivatives } from 'features/detailed-comparator/hooks/useDerivatives';
import { useBrandConfig } from 'shared/hooks/useBrandConfig';

interface Props {
  formValuesProp: FormValues;
  onSubmit: (data: ComparisonResponse, vehicleId: string, registrationYear: string) => void;
  onCancel: (event: KeyboardEvent | React.SyntheticEvent) => void;
  isInitialSlot: boolean;
  product: Product;
  currentVehicle?: {
    manufacturerName: string;
    capId: string;
    fuelType: FuelCategory;
    modelName: string;
    modelRaw: string;
    registrationYear: string;
  };
}

enum FormInputs {
  MANUFACTURER = 'manufacturer',
  REGISTRATION_YEAR = 'registrationYear',
  MODEL = 'model',
  TRIM = 'derivative',
}

export const findCodeByNameInList = (nameToFind: string, list: { name: string; code: number }[]): number => {
  const [item] = list.filter((listItem) => listItem?.name === nameToFind);
  const { code } = item || {};
  return code;
};

const AddVehicleForm: React.FC<Props> = ({
  formValuesProp,
  onSubmit,
  onCancel,
  isInitialSlot,
  product,
  currentVehicle,
}) => {
  const initialState = {
    formValues: {
      manufacturerId: '',
      modelId: '',
      derivativeId: '',
      registrationYear: '',
    },
    vehicleId: '',
    hasError: false,
  };

  const theme = useTheme();
  const brand = useBrand();
  const [hasUserSelectedAnything, setHasUserSelectedAnything] = useState(false);
  const [formState, setFormState] = useState(initialState);
  const { t: translate } = useTranslation(`${brand}.${product}`);
  const { logEvent } = useAnalytics();
  const plausible = usePlausible();
  const brandConfig = useBrandConfig()[product];
  const { vehicleId, formValues } = formState;
  const { derivativeId, modelId, manufacturerId, registrationYear } = formValues;
  const { earliestRegistrationYearPermitted } = brandConfig;
  const shouldShowRegistrationYear = Boolean(earliestRegistrationYearPermitted);
  const isFormValid =
    manufacturerId !== '' && modelId !== '' && derivativeId !== '' && (!shouldShowRegistrationYear || registrationYear);
  const { isLoading, data, error } = useComparison(
    isFormValid ? [vehicleId, registrationYear] : '',
    brand,
    product,
    'POST',
    formValuesProp
  );

  const { data: manufacturer } = useManufacturer({
    brand: getSubBrandMapping(brand),
    product,
  });
  const { code } = manufacturer || {};

  // pre-select the manufacturer for the initial slot
  useEffect(() => {
    if (isInitialSlot && code) {
      setFormState((currentFormState) => ({
        ...currentFormState,
        formValues: {
          ...currentFormState.formValues,
          manufacturerId: String(code),
        },
        shouldFetchModel: true,
      }));
    }
  }, [isInitialSlot, code]);

  useEffect(() => {
    if (!isUndefined(error)) {
      setFormState((formState) => ({
        ...formState,
        hasError: true,
      }));
    }

    if (!isUndefined(data)) {
      onSubmit(data, vehicleId, registrationYear);
    }
  }, [onSubmit, vehicleId, registrationYear, data, error]);

  const { isLoading: isLoadingManufacturers, data: manufacturerData } = useManufacturers({
    product,
    filters: brandConfig?.filters?.useManufacturersFilters,
  });

  const { data: modelData } = useModels({
    manufacturerId,
    product,
    filters: brandConfig?.filters?.useModelsFilters,
    registrationYear,
  });

  const { data: derivativeData } = useDerivatives({
    modelId,
    product,
    registrationYear,
  });

  const { capId, manufacturerName } = currentVehicle || {};
  const modelName = currentVehicle?.modelRaw || currentVehicle?.modelName;

  // pre-select manufacturer if available
  useEffect(() => {
    if (!hasUserSelectedAnything && manufacturerName && manufacturerData) {
      const { manufacturers = [] } = manufacturerData;
      const manufacturerId = findCodeByNameInList(manufacturerName, manufacturers);
      if (manufacturerId) {
        setFormState((state) => ({
          ...state,
          formValues: { manufacturerId: `${manufacturerId}`, registrationYear: '', modelId: '', derivativeId: '' },
          vehicleId: '',
        }));
      }
    }
  }, [hasUserSelectedAnything, manufacturerId, manufacturerName, manufacturerData]);

  const chosenManufacturer = manufacturerData?.manufacturers?.find(({ code }) => `${code}` === manufacturerId);
  const availableYearsForManufacturer = chosenManufacturer?.yearsModelsIntroduced;

  // pre-select registrationYear if available
  const { registrationYear: currentVehicleRegistrationYear } = currentVehicle || {};
  useEffect(() => {
    if (
      !hasUserSelectedAnything &&
      shouldShowRegistrationYear &&
      manufacturerId &&
      currentVehicleRegistrationYear &&
      availableYearsForManufacturer?.includes(+currentVehicleRegistrationYear)
    ) {
      setFormState((state) => ({
        ...state,
        formValues: {
          ...state.formValues,
          registrationYear: currentVehicleRegistrationYear,
          modelId: '',
          derivativeId: '',
        },
        vehicleId: '',
      }));
    }
  }, [
    hasUserSelectedAnything,
    availableYearsForManufacturer,
    manufacturerId,
    shouldShowRegistrationYear,
    currentVehicleRegistrationYear,
  ]);

  // pre-select model if available
  useEffect(() => {
    if (
      !hasUserSelectedAnything &&
      manufacturerId &&
      modelName &&
      modelData &&
      !modelId &&
      (!shouldShowRegistrationYear || registrationYear)
    ) {
      const { models = [] } = modelData;
      const modelId = findCodeByNameInList(modelName, models);
      if (modelId) {
        setFormState((state) => ({
          ...state,
          formValues: { ...state.formValues, modelId: `${modelId}`, derivativeId: '' },
          vehicleId: '',
        }));
      }
    }
  }, [
    hasUserSelectedAnything,
    manufacturerId,
    modelId,
    modelName,
    modelData,
    shouldShowRegistrationYear,
    registrationYear,
  ]);

  // pre-select trim if available
  useEffect(() => {
    if (!hasUserSelectedAnything && modelId && capId && derivativeData) {
      setFormState((state) => ({
        ...state,
        formValues: { ...state.formValues, derivativeId: capId },
      }));
    }
  }, [hasUserSelectedAnything, modelId, capId, derivativeData]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();

    const analyticSlotType = isInitialSlot ? 'add_brand' : 'compare';

    plausible(`detailed_comparator_${analyticSlotType}_vehicle`);

    setFormState({
      ...formState,
      vehicleId: derivativeId,
    });
  };

  const handleSelection = ({ e, name }: { e: React.SyntheticEvent; name: FormInputs }) => {
    const { value } = e.target as unknown as { value: string };
    logEvent(`/add-vehicle-form/${name}/${value}`);
    // stop the pre-selection firing again once the user has selected anything
    setHasUserSelectedAnything(true);

    switch (name) {
      case FormInputs.MANUFACTURER:
        setFormState((currentFormState) => ({
          ...currentFormState,
          formValues: {
            manufacturerId: value,
            modelId: '',
            derivativeId: '',
            registrationYear: '',
          },
          vehicleId: '',
          hasError: false,
        }));
        return null;
      case FormInputs.REGISTRATION_YEAR:
        setFormState((currentFormState) => ({
          ...currentFormState,
          formValues: {
            ...currentFormState.formValues,
            registrationYear: value,
            modelId: '',
            derivativeId: '',
          },
          vehicleId: '',
          hasError: false,
        }));
        return null;
      case FormInputs.MODEL:
        setFormState((currentFormState) => ({
          ...currentFormState,
          formValues: {
            ...currentFormState.formValues,
            modelId: value,
            derivativeId: '',
          },
          vehicleId: '',
          hasError: false,
        }));
        return null;
      case FormInputs.TRIM:
        setFormState((currentFormState) => ({
          ...currentFormState,
          formValues: {
            ...currentFormState.formValues,
            derivativeId: value,
          },
          hasError: false,
        }));
        return null;
      default:
        return null;
    }
  };

  const registrationYearOptions = [
    {
      value: '',
      label: translate('add-vehicle-form.select-placeholder.registrationYear'),
    },
    ...makeRegistrationYears(earliestRegistrationYearPermitted, availableYearsForManufacturer),
  ];
  const shouldMakeModelOptions = Boolean(
    !shouldShowRegistrationYear || (registrationYearOptions.length > 1 && registrationYear)
  );
  const modelOptions = [
    {
      value: '',
      label: translate('add-vehicle-form.select-placeholder.model'),
    },
    ...makeModelOptions((shouldMakeModelOptions && modelData?.models) || []),
  ];
  const shouldMakeTrimOptions = Boolean(modelOptions.length > 1 && modelId);
  const trimOptions = [
    {
      value: '',
      label: translate('add-vehicle-form.select-placeholder.derivative'),
    },
    ...makeTrims((shouldMakeTrimOptions && derivativeData?.derivatives) || []),
  ];

  const [isDivVisible, setIsDivVisible] = useState(true);
  const isRegistrationYearDisabled = !manufacturerId;
  const isModelDisabled = manufacturerId === '' || (shouldShowRegistrationYear && !registrationYear);
  const isDerivativeDisabled = modelId === '';

  return (
    <form className="form" data-test="add-vehicle-form">
      {(isLoading || isLoadingManufacturers) && <Loader />}
      {/*
        This div is to prevent an issue on IOS 15/16 where the select inputs cannot be opened
        see https://drivvn.atlassian.net/browse/COMPRO-622
      */}
      {(isDivVisible && <div tabIndex={0} onFocus={() => setIsDivVisible(false)} />) || null}

      {!isLoading && !isLoadingManufacturers && !isUndefined(manufacturerData) && (
        <>
          {!isInitialSlot && (
            <div className="form__group">
              <Label htmlFor="manufacturerId" text={translate('add-vehicle-form.select-label.brand')} />

              <Select
                id="manufacturerId"
                options={[
                  { value: '', label: 'Select a manufacturer' },
                  ...makeOptions(
                    manufacturerData.manufacturers?.filter(
                      (manufacturer) =>
                        !shouldShowRegistrationYear ||
                        manufacturer.yearsModelsIntroduced.some((year) => {
                          const today = new Date();
                          const earliestStartDate = today.getFullYear() - earliestRegistrationYearPermitted;

                          return year >= earliestStartDate;
                        })
                    ) ?? []
                  ),
                ]}
                onChange={(e) => {
                  handleSelection({ e, name: FormInputs.MANUFACTURER });
                }}
                value={manufacturerId}
                data-test="add-vehicle__select-manufacturer"
              />
            </div>
          )}

          {shouldShowRegistrationYear && (
            <div className="form__group">
              <Label
                htmlFor="registrationYearId"
                text={translate('add-vehicle-form.select-label.registrationYear')}
                disabled={isRegistrationYearDisabled}
              />

              <Select
                id="registrationYearId"
                options={registrationYearOptions}
                onChange={(e) => {
                  handleSelection({ e, name: FormInputs.REGISTRATION_YEAR });
                }}
                value={registrationYear}
                data-test="add-vehicle-select-registrationYear"
                disabled={isRegistrationYearDisabled}
              />
            </div>
          )}

          <div className="form__group">
            <Label
              htmlFor="modelId"
              text={translate('add-vehicle-form.select-label.model')}
              disabled={isModelDisabled}
            />

            <Select
              id="modelId"
              options={modelOptions}
              onChange={(e) => {
                handleSelection({ e, name: FormInputs.MODEL });
              }}
              value={modelId}
              data-test="add-vehicle__select-model"
              disabled={isModelDisabled}
            />
          </div>

          <div className="form__group">
            <Label
              htmlFor="derivativeId"
              text={translate('add-vehicle-form.select-label.derivative')}
              disabled={isDerivativeDisabled}
            />
            <Select
              id="derivativeId"
              options={trimOptions}
              onChange={(e) => {
                handleSelection({ e, name: FormInputs.TRIM });
              }}
              value={derivativeId}
              data-test="add-vehicle__select-derivative"
              disabled={isDerivativeDisabled}
            />
          </div>

          <div className="form__buttons">
            <PrimaryButton
              text={translate('add-vehicle-form.submit')}
              onClick={handleSubmit}
              disabled={!isFormValid}
              data-test="add-vehicle__submit-button"
            />

            <SecondaryButton
              text={translate('add-vehicle-form.cancel')}
              onClick={onCancel}
              data-test="add-vehicle__cancel-button"
            />
          </div>

          {formState.hasError && <FormError text={translate('add-vehicle-form.error')} />}
        </>
      )}

      <style jsx>
        {`
          .form {
            font: ${theme.components.findVehicleForm.font};
          }

          .form__title {
            text-align: center;
            padding: ${theme.spacing.xl};
            margin-bottom: ${theme.spacing.xl};
            background: #eee;
          }

          .form__group {
            padding: ${theme.spacing.md} 0 0 0;
            margin-bottom: ${theme.spacing.md};
            display: block;
          }

          .form__buttons {
            margin: ${theme.components.findVehicleForm.buttons.margin.mobile};
            display: flex;
            flex-direction: ${theme.components.form.buttons.flexDirection};
            justify-content: ${theme.components.form.buttons.justifyContent};
          }

          :global(.form__buttons > button:first-of-type) {
            margin: ${theme.components.findVehicleForm.buttons.first.margin.mobile};
          }

          :global(.form__buttons > .btn:first-of-type) {
            margin-right: ${theme.spacing.md};
          }

          @media (min-width: 900px) {
            .form__buttons {
              margin: ${theme.components.findVehicleForm.buttons.margin.desktop};
            }

            :global(.form__buttons > button:first-of-type) {
            }
          }
        `}
      </style>
    </form>
  );
};

export default AddVehicleForm;
